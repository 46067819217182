import { useSelector } from 'react-redux';
import SpaIcon from '@mui/icons-material/Spa';
import '../css/Home.css'
import Navbar from './Navbar';
import Post from './Post';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();
function Home() {
  
  const [posts, setPosts] = useState([]);
  const username = useSelector((state) => state.auth.username);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://backend-production-ae9c.up.railway.app/api/v1/allPosts');
        setPosts(response.data.posts);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPosts();
  }, []);



  return (
    <>
 <Navbar title='Admin Dashboard'/>
<ThemeProvider theme={theme}>
<Dashboard />
</ThemeProvider>
</>
  )
}

export default Home