import React, { useState } from 'react';
import Navbar from './Navbar'
import emailjs from 'emailjs-com';
import { Box } from '@mui/material';
function Contact() {
    
  return (
   <>
   <Navbar title={'Contact'}/>
   <Box
      sx={{
        width: 300,
        height: 300,
        backgroundColor: 'primary.dark',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    />
   </>
  )
}

export default Contact