import logo from './logo.svg';
import Login from './components/Login';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Ipost from './components/Ipost';
import Contact from './components/Contact';
function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
   <Routes>
   <Route path="/" element={<Login/>}/>
   <Route path="/home" element={<Home />}/>
   <Route path="/ipost/:postId" element={<Ipost />} />
   <Route path="/contact" element={<Contact />} />
   </Routes>
   </BrowserRouter>
    </div>
  );
}

export default App;
