import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Skeleton,
} from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ShareIcon from '@mui/icons-material/Share';
import axios from 'axios';
import Navbar from './Navbar';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../css/Ipost.css';

function Ipost() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetchPost();
  }, []);

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `https://backend-production-ae9c.up.railway.app/api/v1/post/${postId}`
      );
      const postData = response.data.post;
      setPost(postData);
    } catch (error) {
      console.log('Error fetching post:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  }

  if (loading) {
    return (
      <>
        <Navbar title="Loading" /> {/* You can set an empty title or a loading indicator */}
        <Box sx={{ p: 2 }}>
          {/* Display a loading skeleton while data is being fetched */}
          <Skeleton variant="rectangular" height={200} sx={{ marginBottom: '16px' }} />
          <Skeleton variant="text" sx={{ marginBottom: '16px' }} />
          <Skeleton variant="text" sx={{ marginBottom: '16px' }} />
          <Skeleton variant="text" sx={{ marginBottom: '16px' }} />
        </Box>
      </>
    );
  }

  if (!post) {
    return null; // or display a loading indicator
  }

  const { title, description, image } = post;

  const images = image && image.length > 0
    ? image.map((img) => ({
        original: img.path,
        thumbnail: img.path,
      }))
    : [];

  const sanitizeDescription = (rawDescription) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = rawDescription;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  return (
    <div>
    <Navbar title={post.title} />
  
    <div className="containers">
      
      <Card variant="outlined" sx={{ borderRadius: '12px' }}>
        <CardContent sx={{ backgroundColor: 'white' }}>
        <ImageGallery
              items={images}
              showFullscreenButton={true} // Enable fullscreen button
              showPlayButton={false}
              thumbnailPosition="bottom" // Set thumbnail position
              showNav={false}
              showBullets={true}
              sx={{
                height: '50%',
              }}
            />
          <Typography variant="h5" sx={{
            fontFamily: 'Arial, sans-serif',
            fontWeight: 'bold',
            marginBottom: '16px',
          }}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{
            fontFamily: 'Arial, sans-serif',
            lineHeight: '1.8',
          }}>
            {sanitizeDescription(description)}
          </Typography>
        </CardContent>
        <CardActions disableSpacing >
          <Box sx={{ display: 'flex',flexDirection:'column'}}>
            
            <Typography variant="body2" sx={{ fontWeight: 'bold',color:'green'}}>
              {post.views} Views
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold',color:'blue' }}>
              {new Date(post.createdAt).toLocaleString()}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    </div>
    </div>
  );
}

export default Ipost;
