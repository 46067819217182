// new checkpoint
import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Box,
  Button,
  Modal,
  Pagination,
  Backdrop,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Dashboard.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LinearProgress from '@mui/material/LinearProgress';
import { useMediaQuery } from '@mui/material';
import { useAuth } from './useAuth';

const Dashboard = () => {
  useAuth();

  const navigate = useNavigate(); 
  const [page, setPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [formattedContent, setFormattedContent] = useState('');

const [noPosts, setNoPosts] = useState(false);
  const [alert, setAlert] = useState(false);
  const [content, setContent] = useState('');
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [posts_title, setPosts_title] = useState([]);
  const [ctitle, setcTitle] = useState('');
  const [cdescription, setcDescription] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [editPostId, setEditPostId] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [imageError, setImageError] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const limit = 3; // Number of posts per page
  const [postId, setPostId] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  useEffect(() => {
    fetchPosts();
    fetchPosts_title()
  }, []);

  useEffect(() => {
    if (isRedirecting && postId) {
      // Check if postId is available and redirect only if it exists
      const timer = setTimeout(() => {
        setIsRedirecting(false);
        // Redirect to `ipost` after the delay
        navigate(`/ipost/${postId}`);
      }, 2000);

      return () => clearTimeout(timer); // Clean up the timer on unmount
    }
  }, [isRedirecting, postId, navigate]);
  
  useEffect(() => {
    if (totalPages === 0) {
      // Set noPosts state to true if there are no posts
      setNoPosts(true);
    } else {
      // Set noPosts state to false if there are posts
      setNoPosts(false);
    }
  }, [totalPages]);

  useEffect(() => {
    // Reload the page when noPosts state changes to true
    if (noPosts) {
      // Clear the noPosts state after a short delay
      const timeout = setTimeout(() => {
        setNoPosts(false);
      }, 1000);
      
      // Clear the timeout when the component unmounts
      return () => clearTimeout(timeout);
    }
  }, [noPosts]);


  const fetchPosts_title = async () => {
    try {
      const response = await axios.get(
        'https://backend-production-ae9c.up.railway.app/api/v1/allposts?&page=1'
      );
      setPosts_title(response.data.posts);
    } catch (error) {
      // console.log('Error fetching posts:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const fetchPosts = async (page) => {
    const limit = 3; // Number of posts per page
  
    try {
      setLoadingPagination(true);
      const response = await axios.get(
        `https://backend-production-ae9c.up.railway.app/api/v1/allposts?limit=${limit}&page=${page}`
      );
  
      if (response.data.posts.length > 0) {
        setPosts(response.data.posts);
        setTotalPages(Math.ceil(response.data.totalCount / limit));
      } else {
        setPosts([]);
        setTotalPages(0); // Set totalPages to 0 when there are no posts
      }
    } catch (error) {
      console.log('Error fetching posts:', error);
    } finally {
      setLoadingPagination(false); 
      setIsLoading(false); // Stop loading
    }
  };
  
  
  
  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state
    fetchPosts(value);
  };
  const handleCancel = () => {
    setEditPostId(null);
    setTitle('');
    setDescription('');
    setFiles([]);
    setOpen(false); // Close the modal
  };
  
   <Button
      variant="contained"
      color="secondary"
      disabled={loading}
      onClick={handleCancel} // Call the handleCancel function
      className="create-button ml-2"
    >
      Cancel
    </Button>

  const handleOpen = (postId) => {
    setOpen(true);
    const postToEdit = posts.find((post) => post._id === postId);
    if (postToEdit) {
      setEditPostId(postToEdit._id);
      setTitle(postToEdit.title);
      setDescription(postToEdit.description);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreatePost = async () => {
    try {
      setLoading(true);
      setTitleError('');
      setDescriptionError('');
      setImageError('');
  
      if (!ctitle) {
        setTitleError('Title is required');
        return;
      }
  
      if (!cdescription) {
        setDescriptionError('Description is required');
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 1000);
        return;
      }
  
      const formData = new FormData();
      formData.append('title', ctitle);
      formData.append('description', cdescription);
      for (let i = 0; i < files.length; i++) {
        formData.append('image', files[i]);
      }
  
      await axios.post(
        'https://backend-production-ae9c.up.railway.app/api/v1/createPost',
        formData
      );
  
      fetchPosts(1);
      fetchPosts_title();
  
      setcTitle('');
      setcDescription('');
      setContent('');
      setFiles([]);
    } catch (error) {
      console.log('Error creating post:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleDeletePost = async (postId) => {
    try {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [postId]: true,
      }));
      await axios.delete(
        `https://backend-production-ae9c.up.railway.app/api/v1/deletePost/${postId}`
      );
  
      // Refresh the page after successfully deleting the post
      window.location.reload();
    } catch (error) {
      console.log('Error deleting post:', error);
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [postId]: false,
      }));
    }
  };
  

  const handleUpdatePost = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      for (let i = 0; i < files.length; i++) {
        formData.append('image', files[i]);
      }

      await axios.put(
        `https://backend-production-ae9c.up.railway.app/api/v1/update/${editPostId}`,
        formData
      );

      fetchPosts(page, 3);

      setEditPostId(null);
      setTitle('');
      setDescription('');
      setFiles([]);
    } catch (error) {
      console.log('Error updating post:', error);
    } finally {
      setLoading(false);
    }
  };

  const cancelEdit = () => {
    setEditPostId(null);
    setTitle('');
    setDescription('');
    setFiles([]);
  };

  const handleEditorChange = (value) => {
    setContent(value);
    setcDescription(value); // Use cdescription to store the formatted text
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const navigate = useNavigate();

  const handleRedirect = (postId) => {
    // Step 3: Set the postId state to trigger the loading effect and redirect
    setPostId(postId);
    setIsRedirecting(true);
  };
  const getDescription = (text) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;
    return tempDiv.textContent || tempDiv.innerText || '';
  };
  const numberOfPosts = posts_title.length;

  const ReadMoreTypography = ({ text, postId, maxLength }) => {
    const typographyStyle = {
      maxWidth: '800px',
      fontSize: 'inherit',
      cursor: 'pointer',
    };
  
    const [showFullContent, setShowFullContent] = useState(false);
  
    const handleShowFullContent = () => {
      setShowFullContent(true);
    };
    const handleShowFullContents = () => {
      navigate(`/ipost/${postId}`);
    };
  
    const formattedContent = showFullContent ? text : text.slice(0, maxLength);
  
    return (
      <div>
        {showFullContent ? (
          <Typography
            variant="body2"
            color="textSecondary"
            onClick={() => {
              setShowFullContent(false);
            }}
            style={typographyStyle}
          >
            {formattedContent}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            onClick={handleShowFullContents}
            style={typographyStyle}
          >
            <div dangerouslySetInnerHTML={{ __html: formattedContent }} />
          </Typography>
        )}
  
  {text.length > maxLength && !showFullContent && (
      <Typography
        variant="body2"
        color="textSecondary"
        onClick={handleShowFullContents}
        style={{ cursor: 'pointer', fontWeight: 'bold', padding: '10px' }}
      >
        Read More
      </Typography>
    )}
      </div>
    );
  };

  return (
    <div className="containers">
      {/* Create Post Section */}
      <Grid container spacing={2} className="responsive_grid">
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              gutterBottom
              sx={{
                fontFamily: 'monospace',
                fontWeight: 'bold',
                backgroundColor: 'white',
                padding: 1,
              }}
            >
              Create Post
            </Typography>
          </Box>

          <Card variant="elevation" sx={{ padding: '30px',display:'flex',flexDirection:'column',justifyContent:'left',alignItems:'left'}}>
            <TextField
              label="Title"
              variant="outlined"
              required
              fullWidth
              value={ctitle}
              onChange={(e) => setcTitle(e.target.value)}
              className="form-input"
              sx={{ marginBottom: '16px' }}
              error={titleError !== ''}
              helperText={titleError}
            />

            <ReactQuill value={content} onChange={handleEditorChange}
            placeholder='Enter Description'
            />
            <br/>
            <br/>
            {/* <input
              type="file"
              multiple
              onChange={(e) => setFiles(Array.from(e.target.files))}
              className="file-input"
            /> */}
            {/* make input for image upload with drag and drop too */}
            <input
              type="file"
              multiple
              onChange={(e) => setFiles(Array.from(e.target.files))}
              className="file-input"

              style={{
                margin: '10px auto',
              }}
            />


            {alert && (
              <Box sx={{ color: 'red', mt: 1 }}>
                Description cannot be empty!
              </Box>
            )}
            <br/>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'green',
              width:'20%',
            }}
              disabled={loading}
              onClick={handleCreatePost}
              className="create-button"

            >
              {loading ? <CircularProgress size={20} /> : 'Create'}
            </Button>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              gutterBottom
              sx={{
                fontFamily: 'monospace',
                fontWeight: 'bold',
                backgroundColor: 'white',
                padding: 1,
              }}
            >
              Titles
            </Typography>
          </Box>

          <Card
            sx={{
              padding: 3,
              maxHeight: 266,
              height: 266,
              overflowY: 'auto',
            }}
          >
            {isLoading ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            ) : (
              posts_title.map((post) => (
                <Typography
                  variant="h6"
                  component="h2"
                  className="post-titles"
                  padding={2}
                  key={post._id}
                >
                  <li style={{ listStyle: 'none' }}>
                    {' '}
                    <a href="">{post.title}</a>
                  </li>
                </Typography>
              ))
            )}
            {/* <Pagination
  count={totalPages}
  page={page}
  onChange={handlePageChange}
/> */}
          </Card>
        </Grid>
      </Grid>

      <>
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          sx={{
            fontFamily: 'monospace',
            fontWeight: 'bold',
            padding: 1,
            textAlign: 'center',
          }}
        >
          My Post List
          </Typography>
        <>
        {isLoading ? (
        <Box sx={{ width: '100%', marginTop: '20px' }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontFamily: 'monospace',
              textAlign: 'center',
            }}
          >
            Gathering
          </Typography>
          <LinearProgress />
        </Box>
      ) : (
        posts.map((post) => (
          <div className="" key={post._id}>
            <Card variant="elevation" sx={{ padding: '1px', margin: 2 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    {post.image && post.image[0] && post.image[0].path && (
                      <img
                        src={post.image[0].path}
                        alt="Post Image"
                        className="post-image"
                        style={{ width: '100%', height: '240px' }}
                        onClick={() => handleRedirect(post._id)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          padding: 1,
                          borderRadius: 2,
                          fontSize: 28,
                        }}
                        onClick={() => handleRedirect(post._id)}
                      >
                        {post.title}
                      </Typography>
                      <ReadMoreTypography
                        text={post.description}
                        maxLength={400}
                        postId={post._id}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
                  {/* convet to datetime only */}
                  {/* <Typography variant="body2" color="text.secondary">
                  created at: {new Date(post.createdAt).toLocaleString()}
                  </Typography> */}

                  <hr style={{ width: '100%' }} />
                  <CardActions>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      disabled={loadingStates[post._id]}
                      onClick={() => handleDeletePost(post._id)}
                      className="delete-button"
                    >
                      {loadingStates[post._id] ? (
                        <CircularProgress size={20} />
                      ) : (
                        'Delete'
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => handleOpen(post._id)}
                      className="edit-button"
                    >
                      Edit
                    </Button>
                  </CardActions>
                  <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 1,
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Created at: {new Date(post.createdAt).toLocaleString()}
                </Typography>
              </Box>
                </Card>
              </div>
            ))
          )}
        </>
      </>
      {totalPages === 0 && (
        <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'center', marginTop: '20px' }}>
          No posts found.
        </Typography>
      )}
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '5px',
          }}
        >
          <Card
            variant="elevation"
            sx={{
              padding: 3,
              maxHeight: 280,
              height: 280,
              overflowY: 'auto',
            }}
          >
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-input"
              sx={{ marginBottom: '16px' }}
            />
            <ReactQuill value={description} onChange={setDescription} />
            <Grid item xs={12}>
              <input
                type="file"
                multiple
                onChange={(e) => setFiles(Array.from(e.target.files))}
                className="file-input"
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleUpdatePost}
              className="create-button"
            >
              {loading ? <CircularProgress size={24} /> : 'Update'}
            </Button>
            <Button
      variant="contained"
      color="secondary"
      disabled={loading}
      onClick={handleCancel} // Call the handleCancel function
      className="create-button ml-2"
    >
      Cancel
    </Button>
          </Card>
        </div>
      </Modal>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
  <Pagination count={Math.ceil(numberOfPosts/limit)} page={page} onChange={handlePageChange} />
  
  {/* <Typography>
    {numberOfPosts}
  </Typography> */}
</Box>

{loadingPagination && (
        <Backdrop open={loadingPagination} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* Step 4: Show a loading indicator while the redirect is in progress */}
      {isRedirecting && (
        <Backdrop open={isRedirecting} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      
    </div>
  );
};

export default Dashboard;