import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SpaIcon from '@mui/icons-material/Spa';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch,useSelector } from 'react-redux';
import { login } from '../states/store.js';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';


function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const [alertMessage, setAlertMessage] = React.useState('');
  const [showAlert, setShowAlert] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await axios.post('https://backend-production-ae9c.up.railway.app/api/v1/login', {
          username: values.username,
          password: values.password,
        });
    
        // Store login status in localStorage
        dispatch(login({ username: values.username, isLoggedIn: true }));
        localStorage.setItem('isLoggedIn', 'true');
    
        // Check if the user is already logged in and redirect to /home
        if (isLoggedIn) {
          navigate('/home');
        } else {
          navigate('/'); // If not logged in, navigate to login page
        }
      } catch (error) {
        setAlertMessage('Wrong username or password!');
        setShowAlert(true);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn, navigate]);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex' }}>
          <SpaIcon style={{ color: 'green' }} />
          <Typography component="h1" variant="h5" sx={{
          cursor:'pointer'
          }} 
          onClick={()=>{
            navigate('/home')
          }}
          >
            Bio Diversity
          </Typography>
        </div>

        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          {showAlert && (
            <>
            <CloseIcon onClick={handleAlertClose}
            style={{
              cursor:'pointer',
              color:'blue',
              display:'flex',
              justifyContent:'flex-end'
              
            }}
          ></CloseIcon>
            <div className="alert" style={{
              color:'red'
            }}>
              {alertMessage} 
              
            </div>
            </>
          )}
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && formik.errors.username ? true : false}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password ? true : false}
            helperText={formik.touched.password && formik.errors.password}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={isLoading} // Show loading state based on `isLoading`
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
