// store.js
import { configureStore, createSlice } from '@reduxjs/toolkit';
const storedIsLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    username: localStorage.getItem('username') || '',
    isLoggedIn: storedIsLoggedIn,
  },
  reducers: {
    login: (state, action) => {
      state.username = action.payload.username;
      state.isLoggedIn = true;
      localStorage.setItem('isLoggedIn',action.payload.isLoggedIn)
      localStorage.setItem('username', action.payload.username);
    },
    logout: (state) => {
      state.username = '';
      state.isLoggedIn = false;
      localStorage.removeItem('username');
      localStorage.removeItem('isLoggedIn')
    },
  },
});

export const { login, logout } = authSlice.actions;

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
  },
});

export default store;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;