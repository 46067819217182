import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import SpaIcon from '@mui/icons-material/Spa';
import '../css/Home.css'
import { useDispatch } from 'react-redux';
import { logout } from '../states/store';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';

function Navbar(props) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn); // Retrieve isLoggedIn state from Redux

  const navigate = useNavigate();
  const { sections, title } = props;
  const username = useSelector((state) => state.auth.username);
  const [isTransparent, setIsTransparent] = React.useState(true);
  const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < 600);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 600);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to handle the logout click event
  const handleLogout = () => {
    // Clear isLoggedIn status in Redux and localStorage
    dispatch(logout());
    localStorage.removeItem('isLoggedIn');
    navigate('/'); // Redirect to login page
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { label: 'Home', onClick: () => navigate('/home') },
    { label: 'Logout', onClick: handleLogout },
  ];

  return (
    <Container>
      <Toolbar
        component="nav"
        variant="dense"
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          overflowX: 'auto',
          justifyContent: 'space-between',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
        className={isTransparent ? 'navbar-transparent' : ''}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isSmallScreen ? (
            <>
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleMenu}>
              <MenuIcon />
              
            </IconButton>
            <a className="navbar-brand greet" href="#">
              <div>
                <SpaIcon style={{ color: 'green' }} />
                Bio Diversity
              </div>
              <p className="navbar-brand name">Hi, {username}</p>
            </a>
            </>
          ) : (
            <a className="navbar-brand greet" href="#">
              <div>
                <SpaIcon style={{ color: 'green' }} />
                Bio Diversity
              </div>
              <p className="navbar-brand name">Hi, {username}</p>
            </a>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            
            color="green"
            gutterBottom
            sx={{ fontFamily: 'monospace', fontWeight: 'bold', padding: 1 }}
          >
            <img src="nature.gif" alt="" srcSet="" style={{ width: '40px' }} />
            {title}
          </Typography>
          {/* <IconButton sx={{ mr: 2 }}>
            <SearchIcon />
          </IconButton> */}
        </div>

        {/* Navigation options for larger screens */}
        {!isSmallScreen && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <Typography
              sx={{ mr: 5 }}
              onClick={() => {
                navigate('/home');
              }}
            >
              Home
            </Typography>
            <Button variant="outlined" size="small" color="primary" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        )}
      </Toolbar>

      {/* Drawer for responsive navigation */}
      <Drawer anchor="left" open={isMenuOpen} onClose={toggleMenu} sx={{
        textAlign: 'center',
      
      }}>
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.label} onClick={item.onClick}>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
        <a variant="outlined" size="small" color="primary" onClick={toggleMenu} style={{cursor: 'pointer'}}>
          X
        </a>
      </Drawer>
    </Container>
  );
}

Navbar.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};

export default Navbar;
