import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../states/store';

export const useAuth = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (!isLoggedIn) {
    // Redirect to the login page or any other page you want to show for unauthorized users
    window.location.href = '/';
  }
};
